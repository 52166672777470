<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import {
  Aside as TAside,
  Button as TButton,
  Content as TContent,
  Icon as TIcon,
  Layout as TLayout,
} from 'tdesign-vue-next'
import { MyHeadMenu, MyMenu } from '@myfront/tdesign-components'
import type { Nav } from '~/app.config'
import {
  navigateTo,
  useAuth,
  useRoute,
  useRuntimeConfig,
  useState,
  watch,
} from '#imports'

const runtimeConfig = useRuntimeConfig()

// 获取配置信息，从app.vue生成
const globalConfig = useState('global-config')

// 菜单导航集合
const navigations = [] as any
const menu = computed(() => {
  const _menu = globalConfig.value.menu;
  Object.keys(_menu).filter((moduleName) => moduleName != 'redirect').forEach((moduleName: string) => {
    navigations.push(..._menu[moduleName].navigation)
  })
  return _menu
})

// 登录态相关信息和操作接口
const { data, signOut } = useAuth()

const route = useRoute()

const mainMenu = computed(() => {
  let [, moduleName = 'pm'] = route.fullPath.split('/')
  // moduleName 不存在与menu时，从navigations里获取
  if (!menu.value[moduleName]) {
    navigations?.some((item: any) => {
      if (route.name?.startsWith(item.name)) {
        if (route.name === 'item.name') {
          moduleName = item.moduleName
        }
        else {
          if (item.name === route.name) {
            moduleName = item.moduleName
            return true
          }
          else if (item.children) {
            item.children.some((n: any) => {
              // TODO目前没有好的匹配方法
              if (route.name?.startsWith(n.name)) {
                moduleName = n.moduleName
                return true
              }
            })
          }
        }
        return true
      }
    })
  }
  // @ts-expect-error
  return menu.value[moduleName]
})

const collapsed = ref(false)

const iconName = computed(() => (collapsed.value ? 'chevron-right' : 'chevron-left'))

function changeCollapsed() {
  collapsed.value = !collapsed.value
}
function getWindowWidth() {
  // if (!collapsed.value) {
  if (window.innerWidth < 1220)
    collapsed.value = true
  else
    collapsed.value = false

  // }
}
// 监听窗口改变
onMounted(() => {
  window.addEventListener('resize', getWindowWidth)
})
// 销毁
onUnmounted(() => {
  // 移除监听事件
  window.removeEventListener('resize', getWindowWidth)
})
// 左侧导航栏点击事件
function clicked(menu: Nav) {
  if (menu.value) {
    // TODO: 得优化
    if (route.path.startsWith('/render'))
      navigateTo(menu.value)
    else
      navigateTo({ name: menu.value })
  }
}

function checkPath(reg: RegExp, _index: number, _array: RegExp[]): boolean {
  const regex = new RegExp(reg, 'g')
  return regex.test(route.fullPath)
}
// 根据name获取当前的菜单项
function currentMenuByName() {
  if (mainMenu.value) {
    const currentName = route.name
    // 查找菜单
    let target = ''
    const findMenu = (menus: Nav[]) => {
      menus.map((menu) => {
        // if (menu.path === currentPath || (menu.meta.match && menu.meta.match.some(checkPath))) {
        if (
          menu.name === currentName
          || (String(currentName).startsWith(menu.name) && !menu.children)
        )
          target = menu.name

        if (!target && menu.children)
          findMenu(menu.children)
      })
    }

    findMenu(mainMenu.value.navigation)
    return target
  }
}

// 根据key获取当前的菜单项，render模块专用
// TODO 需要优化
function currentMenuByKey() {
  if (mainMenu.value) {
    // const currentName = route.name;
    const currentName = route.fullPath
    // 查找菜单
    let target = ''
    const findMenu = (menus: Nav[]) => {
      menus.map((menu) => {
        // if (menu.path === currentPath || (menu.meta.match && menu.meta.match.some(checkPath))) {
        if (
          // menu.name === currentName ||
          menu.meta.key === currentName
          || (String(currentName).startsWith(menu.meta.key) && !menu.children)
        ) {
          // target = menu.name;
          target = menu.meta.key
        }

        if (!target && menu.children)
          findMenu(menu.children)
      })
    }

    findMenu(mainMenu.value.navigation)
    return target
  }
}

const currentMenu = ref('')
const updateMenu = ref(0) // 强制刷新左侧导航栏

watch(
  () => route.path,
  () => {
    // 监听路由路径判断左侧导航栏value(高亮)值使用的是key还是那么
    if (route.path.startsWith('/render'))
      currentMenu.value = currentMenuByKey()
    else
      currentMenu.value = currentMenuByName()

    if (
      route.path === '/bim/1/collection/model'
      || route.path === '/pm/admin/project'
      // route.path === "/render/scene/construct"
      || route.path === '/render/example/scene'
    ) {
      // 重新渲染
      updateMenu.value++
    }
  },
  {
    immediate: true,
  },
)
// 头部导航栏的value值
const headActive = computed(() => {
  if (mainMenu.value) {
    const routeFirst = route.path.split('/')[1]
    let headActiveIs = '' as string
    if (mainMenu.value.headMenu) {
      mainMenu.value.headMenu.map((item: any) => {
        // 修复切换项目中心闪烁问题
        const nameFirst = item.name.split('-')[0]
        if (nameFirst === routeFirst) {
          // headActiveIs = item.path;
          // 修复顶部导航栏高亮问题
          headActiveIs = item.name
        }
      })
    }
    return headActiveIs
  }
  return ''
})
// 左侧导航栏的keys.value值
const keyValue = computed(() => {
  if (route.path.startsWith('/render'))
    return 'key'
  else
    return 'name'
})

const logoSmall = computed(() => {
  if (!globalConfig.value?.main?.brand_logo) {
    return runtimeConfig.public.site.logo
  }
  else if (globalConfig.value?.main?.brand_logo.includes('logo')) {
    // 历史原因这里fileUrl默认含有upload路径
    // 如果包含logo就认定是默认配置的，直接返回
    return globalConfig.value?.main?.brand_logo
  }
  else {
    return getVisitUrl(`${globalConfig.value?.main?.brand_logo}`)
  }
})

const siteName = globalConfig.value?.main?.brand_name || runtimeConfig.public.site.name

const contentWidth = computed(() =>
  collapsed.value ? 'calc(100% - 64px)' : 'calc(100% - 200px)',
)
const leftContentWidth = computed(() => (collapsed.value ? '64px' : '200px'))

function goHome() {
  // navigateTo(runtimeConfig.public.site.defaultPortal)
  navigateTo('/pm/admin/project')
}

// 顶部导航栏点击事件
function handleHeadMenuClicked(e) {
  if (e.value.startsWith('render'))
    navigateTo('/render/example/scene')
  else if (e.value.startsWith('pm'))
    navigateTo('/pm/admin/project')
  // 这里给的是当前组织的oid
  else if (e.value.startsWith('flow'))
    navigateTo({ name: e.value, params: { project_id: globalConfig.value?.oid } })
  else
    navigateTo({ name: e.value })
}
// 跳转当前用户页面
function userInfo() {
  window.location.href = '/user/info'
}

const logoSize = computed(() => {
  return globalConfig.value?.main?.brand_logo_display_size || runtimeConfig.public.site?.logoSize || 20
})

const dropdownOptions = [
  {
    content: data.value.name,
    value: 1,
    onClick: () => userInfo(),
  },
  {
    content: runtimeConfig.public.systemMode === 'single-tenant' ? '我的组织' : '组织空间',
    value: 2,
    onClick: () => navigateTo(`/om/orgs`),
  },
  {
    content: '退出',
    value: 3,
    onClick: () => signOut({ callbackUrl: runtimeConfig.public.logoutBackUrl }),
  },
]
</script>

<template>
<TLayout>
  <MyHeadMenu v-if="mainMenu" :keys="{ value: 'name' }" style="position: sticky; top: 0; z-index: 99" theme="dark"
    class="my-head-menu" :value="headActive" :menu="mainMenu.headMenu || []" @clicked="handleHeadMenuClicked">
    <template #logo>
      <div class="flex items-center">
        <img v-if="!collapsed" :width="logoSize" class="logo cursor-pointer" :src="logoSmall" alt="logo"
          @click="goHome">
        <img v-if="collapsed" :width="logoSize" class="logo cursor-pointer" :src="logoSmall" alt="logo" @click="goHome">
        <div class=" text-white cursor-pointer flex items-center" style="
              font-size: 22px;
              font-weight: 700;
              color: rgb(222, 222, 222);
              margin-left: 10px;
            " @click="goHome">
          {{ siteName }}
        </div>
      </div>
    </template>
    <template #operations>
      <a href="javascript:;">
        <TIcon class="t-menu__operations-icon" name="search" />
      </a>
      <a href="javascript:;">
        <TIcon class="t-menu__operations-icon" name="notification-filled" />
      </a>

      <t-dropdown trigger="hover" :options="dropdownOptions" :min-column-width="88">
        <a href="javascript:;">
          <TIcon class="t-menu__operations-icon" name="user" />
        </a>
      </t-dropdown>
    </template>
  </MyHeadMenu>
  <TLayout>
    <div style="">
      <TAside class="menu-left" :style="{
        position: 'fixed',
        borderTop: '1px solid var(--component-border)',
        width: `${leftContentWidth}`,
        zIndex: '99',
      }">
        <MyMenu v-if="mainMenu" :key="updateMenu" :title="mainMenu.title" :collapsed-icon="mainMenu.collapsedIcon"
          :keys="{ value: keyValue }" :value="currentMenu" :expanded="mainMenu.expanded" theme="dark" :style="{
            'height': 'calc(100vh - 60px)',
            '--td-font-white-2': 'rgb(193, 198, 200)',
          }" :collapsed="collapsed" :menu="mainMenu.navigation" @clicked="clicked">
          <template #operations>
            <TButton class="t-demo-collapse-btn" variant="text" shape="square" @click="changeCollapsed">
              <template #icon>
                <TIcon color="rgb(193, 198, 200)" :name="iconName" />
              </template>
            </TButton>
          </template>
        </MyMenu>
      </TAside>
    </div>

    <TLayout class="w-full">
      <TContent>
        <div class="outer-scrollbar" :style="{
          height: 'calc(100vh - 60px)',
          overflow: 'auto',
          width: '100%',
          paddingLeft: `calc(100% - ${contentWidth})`,
        }">
          <div class="outer-scrollbar" style="overflow: auto; height: 100%; width: 100%">
            <!-- <div> -->
            <slot />
            <!-- </div> -->
          </div>
        </div>
      </TContent>
    </TLayout>
  </TLayout>
</TLayout>
</template>

<style scoped></style>
